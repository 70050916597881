import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import * as React from "react";
import * as ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
// import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import { Settings } from "@havi/common";
import baseName from "./BaseName";

const rootEl = document.getElementById("root");

function startRoot() {
  const ReduxRoot = React.lazy(() => import("./ReduxRoot"));

  ReactDOM.render(
    <React.Suspense fallback={loader}>
      <ReduxRoot />
    </React.Suspense>,
    rootEl
  );
}

async function index() {
  const start = Date.now();

  await Settings.configure({ basename: baseName });

  const end = Date.now();

  (window as any).DD_LOGS &&
    (window as any).DD_LOGS.logger.info(`In Login UI: settings bootstrapped in ${end - start} ms`);

  // const metadata = (await axios(Storage.Settings.metadata_uri)).data;
  // const signInKeys = (await axios(metadata.jwks_uri)).data;
  // Storage.LoginMetadata = metadata;
  // Storage.LoginSigninKeys = signInKeys;

  startRoot();
}

const loader = (
  <div
    style={{
      position: "absolute",
      width: "99vw",
      height: "99vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden"
    }}
  >
    <CircularProgress style={{ color: "#006cb6" }} size={70} thickness={2} />
  </div>
);

ReactDOM.render(loader, rootEl);

index();

serviceWorker.unregister();
